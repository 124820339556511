<template>
	<PageLayout hide-back-button>
		<div class="card">
			<div v-if="!success" class="container">
				<Spacer height size="xl" />
				<img class="container-img" :src="require('../../assets/icons/error.png')" />
				<Spacer height size="xl" />
				<h1 class="container-header">{{ $t(errorTitle) }}</h1>
				<span class="container-label">{{ $t(errorSubtitle) }}</span>
			</div>
			<div v-if="success" class="container">
				<Spacer height size="xl" />
				<img class="container-img" :src="require('../../assets/icons/success.png')" />
				<Spacer height size="xl" />
				<h1 class="container-header">{{ $t(successTitle) }}</h1>
				<span class="container-label">{{ $t(successSubtitle) }}</span>
			</div>
			<Spacer height size="xl" />
			<div class="buttons">
				<div class="buttons-content">
					<CButton v-if="showBackToDashboard && !isEmbedded" secondary full-width
						@click="navigateTo('/')">
						{{ $t('actions.back-to-dashboard') }}
					</CButton>
					<CButton v-if="!success && tryAgainLink" full-width primary
						@click="navigateTo(tryAgainLink)">
						{{ $t('actions.try-again') }}
					</CButton>
				</div>
			</div>
		</div>
	</PageLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import CButton from '@/shared/cbutton/CButton';
import Spacer from '@/shared/spacer/Spacer.vue';
const PageLayout = () => import('../page-layout/PageLayout');

export default {
	name: 'Confirmation',
	components: {
		PageLayout,
		CButton,
		Spacer,
	},
	props: {
		success: {
			type: Boolean,
			default: false,
		},
		successTitle: {
			type: String,
			default: '',
		},
		successSubtitle: {
			type: String,
			default: '',
		},
		errorTitle: {
			type: String,
			default: '',
		},
		errorSubtitle: {
			type: String,
			default: '',
		},
		tryAgainLink: {
			type: [String, null],
			required: false,
		},
		showBackToDashboard: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapGetters(['isEmbedded']),
	},
	methods: {
		navigateTo(link) {
			if (link.startsWith('/')) {
				this.$router.push({
					path: link,
				});
			} else {
				window.location.href = link;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.card {
	@include card;
	flex-grow: 1;
	min-width: 100%;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	&-header {
		font-size: $label-xl;
		margin: 0px;
	}

	&-label {
		font-size: $label-md;
		color: $white;
		opacity: 0.5;
	}

	&-img {
		width: 108px;
	}
}

.buttons {
	display: flex;
	justify-content: center;

	&-content {
		display: flex;
		justify-content: center;
		gap: 5px;
		width: 60%;

		@include max-screen($md) {
			flex-direction: column;
			width: 100%;
		}
	}
}
</style>
